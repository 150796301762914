
var template= angular.module('templates', []);

var app = angular.module('nivel', ['templates']);

/*angular.element(function() {
    console.log('instantiate');
    angular.bootstrap(document.body, ['nivel']);
});*/


(function () {
  if ( typeof window.CustomEvent === "function" ) return false; //If not IE

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
